@import "global";

.section.whatwedo {
  height: auto;
}

.floating {
  display: none;
}

.whatwedo {
  &.section {
    overflow-x: unset;
  }

  .section__content {
    &__body-wrapper {
      display: flex;
      max-width: 1000px;
      margin: 0 auto;
      padding-bottom: 75px;
      flex-direction: column;

      &__title {
        flex: 1;

        h2 {
          margin: 0;
          @include font-size(44);
          font-family: $ffHeading;
          color: #fff;
          line-height: 1;
          font-weight: 800;
          background: -webkit-linear-gradient(128deg, rgba(156, 116, 255, 1) 0%, rgba(255, 255, 255, 1) 65%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          padding-bottom: 30px;
        }

        &--small {
          font-weight: 300;
        }
      }

      &__copy {
        display: none;
        flex: 1;
        margin-left: 0;
        padding-bottom: 40px;

        p {
          margin: 0;
          color: #fff;
          @include font-size(16);
          font-family: $ffBodycopy;
          color: #fff;
          line-height: 1.8;
        }
      }
    }

    &__box-wrapper {
      display: flex;
      max-width: 1000px;
      margin: 0 auto;
      flex-direction: column;

      &__item {
        flex: 1 0 100%;
        min-height: 400px;
        background-color: rgb(91, 50, 216);
        border-radius: 0 35px;
        padding: 40px 20px;
        margin: 0 auto 40px auto;
        margin-bottom: 40px;
        min-width: 300px;
        transform: translateY(70px);
        opacity: 0;

        &.isActive {
          transform: translateY(0);
          opacity: 1;
          transition: all 0.8s ease;
        }

        &__icon {
          width: 60px;
          margin: 0 auto;

          svg {
            width: auto;
            height: 100%;

            path {
              fill: #bb9fef;
            }
          }
        }

        &__copy {
          text-align: center;
          color: #fff;
          z-index: 2;
          position: relative;

          h4 {
            @include font-size(40);
            margin: 0;
            font-family: $ffHeading;
            padding: 40px 0;
            font-weight: 800;

          }

          p {
            @include font-size(16);
            margin: 0;
            font-family: $ffBodycopy;
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .section.whatwedo {
    min-height: 100vh;
    height: auto;
  }
  .whatwedo {
    .section__content {
      &__body-wrapper {
        flex-direction: row;
        padding-bottom: 100px;

        &__copy {
          flex: 1;
          margin-left: 80px;
          padding-bottom: 0;

        }

        &__title {
          h2 {
            @include font-size(60);
          }
        }

        &__copy {
          display: none;

          p {
            @include font-size(18);
          }
        }
      }

      &__box-wrapper {
        position: relative;
        flex-direction: row;

        &__item {
          flex: 1 1 0px;
          position: relative;
          margin-bottom: 0;
          margin-right: 15px;
          min-width: unset;
        }

        &__item:last-child {
          margin-right: 0;
        }
      }
    }

    .floating {
      pointer-events: none;
      display: block;

      &--circle {
        border-radius: 50%;
      }
    }

    .f1 {
      @include absolute($top: 50px, $left: -13px);
      width: 25px;
      height: 25px;
      background-color: #8353e3;
      z-index: 10;
    }

    .f2 {
      height: 100%;
      z-index: 1;
      @include absolute($top: -450px, $right: -250px);
    }
  }
}